import React from 'react'
import Layout from '../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'

import '../styles/global.scss'
import '../styles/faq.scss'
import SEO from '../components/seo'

const faq = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Lumineers Facts | Lumineers vs Veneers | How much do Lumineers cost?"
          description="Learn the difference between Lumineers vs veneers, how much Lumineers cost and many facts about Lumineers, the #1 patient-requested dental veneer."
        />
        <div className="faq-container">
          <Container>
            <Row>
              <Col md="12 mt-5">
                <div className="faqs">
                  <h1 className="faq-title pb-3">Frequently Asked Questions</h1>
                  <p className="faq-link">
                    <Link to="/faq/faq-1">What are Lumineers?</Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-2">
                      What's the difference between Lumineers and veneers?
                    </Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-3">How much do Lumineers cost?</Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-4">Can I order Lumineers online?</Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-5">
                      How do I find a dentist that prescribes Lumineers?
                    </Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-6">
                      What can I expect when I go to the dentist?
                    </Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-7">
                      Is the Lumineers procedure painful?
                    </Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-8">How long do Lumineers last?</Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-9">Are Lumineers safe?</Link>
                  </p>
                  <p className="faq-link">
                    <Link to="/faq/faq-10">Can Lumineers be removed?</Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  )
}

export default faq
